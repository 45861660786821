import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import LoaderScreen from "../../../components/LoaderScreen";
import { addNotification } from "../../../components/Notification";
import SimpleReactValidator from "simple-react-validator";

const marketOption = ["Yes", "No"];
const statusOption = ["Enable", "Disable"];
const marginTradingOptions = ["Enable", "Disable"];
const NameAndDescription = ({ className, state, updateMarketHandler, loading }) => {
  const [priceDecimalPrecision, setPriceDecimalPrecision] = useState(state?.priceDecimalPrecision);
  const [amountDecimalPrecision, setAmountDecimalPrecision] = useState(state?.amountDecimalPrecision);
  const [marketOrderValue, setMarketOrderValue] = useState(marketOption[0]);
  const [statusValue, setStatusValue] = useState(state.status === 0 ? "Disable" : "Enable");
  const [marketPrice, setMarketPrice] = useState(state.marketPrice);
  const [currentMarketPrice, setCurrentMarketPrice] = useState(state.currentMarketPrice);
  const [buyPrice, setBuyPrice] = useState(state.buyPrice);
  const [sellPrice, setSellPrice] = useState(state.sellPrice);
  const [dayChange, setDayChange] = useState(state.dayChange);
  const [weekChange, setWeekChange] = useState(state.weekChange);
  const [cap, setCap] = useState(state.cap);
  const [volume, setVolume] = useState(state.volume);
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState("");
  const [botAvgBalanceFrom, setBotAvgBalanceFrom] = useState(state.bot_avg_bal_from);
  const [botAvgBalanceTo, setBotAvgBalanceTo] = useState(state.bot_avg_bal_to);
  const [botCallInterval, setBotCallInterval] = useState(state.bot_call_interval);
  const [botAvgCryptoFrom, setBotAvgCryptoFrom] = useState(state.bot_avg_crypto_from);
  const [botAvgCryptoTo, setBotAvgCryptoTo] = useState(state.bot_avg_crypto_to);
  const [botSpread, setBotSpread] = useState(state.bot_spread?.join(","));
  const [marketPlaceId, setMarketPlaceId] = useState(state?.marketplaceid);
  const [marginTrading, setMarginTrading] = useState(state?.isMarginTrading === 0 ? "Enable" : "Disable");
  const [maxLeverageTrading, setMaxLeverageTrading] = useState(state?.maxLeverageTrading);
  const [marginLeverageSpread, setMarginLeverageSpread] = useState(state?.margin_leverage_spread);
  const [futurePriceDiff, setFuturePriceDiff] = useState("");

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Slug"
            name="title"
            type="text"
            value={state?.slug}
            required
            disabled={true}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Name"
            name="title"
            type="text"
            value={state?.name}
            required
            disabled={true}
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Price"
            name="marketPrice"
            placeholder="Please enter a value"
            value={marketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarketPrice(e.target.value)
              }
            }}
            onBlur={() => simpleValidator.current.showMessageFor("marketPrice")}
            required
          />
          {simpleValidator.current.message("marketPrice", marketPrice, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Current Market Price"
            name="CurrentMarketPrice"
            type="text"
            placeholder="Please enter a value"
            value={currentMarketPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCurrentMarketPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("CurrentMarketPrice")}
          />
          {simpleValidator.current.message("CurrentMarketPrice", currentMarketPrice, "required")}
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Buy Price"
            name="buyPrice"
            type="text"
            placeholder="Please enter a value"
            value={buyPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBuyPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("buyPrice")}
          />
          {simpleValidator.current.message("buyPrice", buyPrice, "required")}

        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Sell Price"
            name="sellPrice"
            type="text"
            placeholder="Please enter a value"
            value={sellPrice}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setSellPrice(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("sellPrice")}
          />
          {simpleValidator.current.message("sellPrice", sellPrice, "required")}
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Day Change"
            name="dayChange"
            type="text"
            placeholder="Please enter a value"
            value={dayChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setDayChange(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("dayChange")}
          />
          {simpleValidator.current.message("dayChange", dayChange, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Week Change"
            name="weekChange"
            type="text"
            placeholder="Please enter a value"
            value={weekChange}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setWeekChange(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("weekChange")}
          />
          {simpleValidator.current.message("weekChange", weekChange, "required")}
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Cap"
            name="cap"
            type="text"
            placeholder="Please enter a value"
            value={cap}
            onChange={(e) => {
              const re = /^\d+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setCap(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("cap")}
          />
          {simpleValidator.current.message("cap", cap, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Volume"
            name="volume"
            type="text"
            placeholder="Please enter a value"
            value={volume}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setVolume(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("volume")}
          />
          {simpleValidator.current.message("volume", volume, "required")}
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Price Decimal Precision"
            name="decimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={priceDecimalPrecision}
            onChange={(e) => {
              setPriceDecimalPrecision(e.target.value)
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("decimalPrecision")}
          />
          {simpleValidator.current.message("decimalPrecision", priceDecimalPrecision, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Amount Decimal Precision"
            name="amountDecimalPrecision"
            type="text"
            placeholder="Please enter a value"
            value={amountDecimalPrecision}
            onChange={(e) => {
              setAmountDecimalPrecision(e.target.value)
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("amountDecimalPrecision")}
          />
          {simpleValidator.current.message("amountDecimalPrecision", amountDecimalPrecision, "required")}

        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Disable Market Order"
            value={marketOrderValue}
            setValue={setMarketOrderValue}
            options={marketOption}
          />
        </div>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Status"
            value={statusValue}
            setValue={setStatusValue}
            options={statusOption}
          />
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <Dropdown
            className={styles.field}
            label="Margin Trading"
            value={marginTrading}
            setValue={setMarginTrading}
            options={marginTradingOptions}
          />
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Max Leverage Trading"
            name="maxLeverageTrading"
            type="text"
            placeholder="Please enter a value"
            value={maxLeverageTrading}
            onChange={(e) => {
              const re = /^\d+(\.\d+)?$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMaxLeverageTrading(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("maxLeverageTrading")}
          />
          {simpleValidator.current.message("maxLeverageTrading", maxLeverageTrading, "required")}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Margin Leverage Spread"
            name="marginLeverageSpread"
            type="text"
            placeholder="Please enter a value"
            value={marginLeverageSpread}
            onChange={(e) => {
              const re = /^\d+(\.\d+)?$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarginLeverageSpread(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("marginLeverageSpread")}
          />
          {simpleValidator.current.message("marginLeverageSpread", marginLeverageSpread, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Market Place Id"
            name="marketPlaceId"
            type="text"
            placeholder="Please enter a value"
            value={marketPlaceId}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setMarketPlaceId(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("marketPlaceId")}
          />
          {simpleValidator.current.message("marketPlaceId", marketPlaceId, "required")}
        </div>
      </div>
      <div className={styles.description}>
        {/* <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Future Price Difference"
            name="futurePriceDiff"
            type="text"
            placeholder="Please enter a value"
            value={futurePriceDiff}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;;
              if (e.target.value === '' || re.test(e.target.value)) {
                setFuturePriceDiff(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("futurePriceDiff")}
          />
          {simpleValidator.current.message("futurePriceDiff", futurePriceDiff, "required")}
        </div> */}
      </div>
      <hr />
      <br />
      <h4 className={styles.description}>Bot Settings Starts from here</h4>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(From)"
            name="botAvgBalanceFrom"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceFrom}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceFrom(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("botAvgBalanceFrom")}
          />
          {simpleValidator.current.message("botAvgBalanceFrom", botAvgBalanceFrom, "required")}
        </div>

        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Balance(To)"
            name="botAvgBalanceTo"
            type="text"
            placeholder="Please enter a value"
            value={botAvgBalanceTo}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgBalanceTo(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("botAvgBalanceTo")}
          />
          {simpleValidator.current.message("botAvgBalanceTo", botAvgBalanceTo, "required")}
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Call Interval"
            name="botCallInterval"
            type="text"
            placeholder="Please enter a value"
            value={botCallInterval}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotCallInterval(e.target.value)
              }
            }}
            required
            onBlur={() => simpleValidator.current.showMessageFor("botCallInterval")}
          />
          {simpleValidator.current.message("botCallInterval", botCallInterval, "required")}
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Crypto(From)"
            name="botAvgCryptoFrom"
            type="text"
            placeholder="Please enter a value"
            value={botAvgCryptoFrom}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgCryptoFrom(e.target.value)
              }
            }}
            required
          />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Average Crypto(To)"
            name="botAvgCryptoTo"
            type="text"
            placeholder="Please enter a value"
            value={botAvgCryptoTo}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotAvgCryptoTo(e.target.value)
              }
            }}
            required
          />
        </div>
        {/* <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Bot Spread"
            name="botSpread"
            type="text"
            placeholder="Please enter a value"
            value={botSpread}
            onChange={(e) => {
              const re = /^[0-9.,]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setBotSpread(e.target.value)
              }
            }}
            required
          />
        </div> */}
      </div>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (botAvgCryptoFrom !== "" && botAvgCryptoTo !== "" &&
              // botSpread !== "" && 
              botAvgBalanceFrom !== "" && botAvgBalanceTo !== "" && botCallInterval !== "" && priceDecimalPrecision !== "" && priceDecimalPrecision >= 0 && amountDecimalPrecision !== "" && amountDecimalPrecision >= 0) {
              if ((priceDecimalPrecision.toString().includes(".")) && (amountDecimalPrecision.toString().includes("."))) {
                addNotification({
                  title: 'Error',
                  message: "Only numeric values are allowed, you can't add decimal values in Price Decimal Precision and Amount Decimal Precision fields.",
                  type: 'danger'
                });
              }
              else {
                // const newArr = botSpread?.split(",");
                // let filteredData = newArr?.filter((x) => {
                //   if (x !== "" || x !== " ") {
                //     return x;
                //   }
                // });
                updateMarketHandler(priceDecimalPrecision, amountDecimalPrecision, marketOrderValue,
                  statusValue, marketPrice, currentMarketPrice, buyPrice, sellPrice, dayChange, weekChange, cap, volume,
                  botAvgBalanceFrom, botAvgBalanceTo, botCallInterval, botAvgCryptoFrom, botAvgCryptoTo,
                  // filteredData, 
                  marketPlaceId, maxLeverageTrading, marginLeverageSpread,
                  //  futurePriceDiff,
                  marginTrading);
              }
            }
            else {
              addNotification({
                title: 'Error',
                message: "Please fill all the fields and make sure the entered data is valid.",
                type: 'danger'
              });
            }
          }
          else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }

        }
        }
        className={cn("button", styles.button)}
      >
        Save Changes
      </button>
    </Card >
  );
};

export default NameAndDescription;
